import React from 'react'

import studentlogo from '../data/studentlogo.png';
const StudentComponent = ({ eleve }) => {
    return (
        <div className="flex gap-2 shadow-xl rounded pl-3 py-3">
            <div className="flex w-16 h-16">
                <img src={studentlogo} alt="" />
            </div>
            <div className="flex-col gap-1 ">
                <h1 className='font-bold text-lg'>{eleve.eleve}</h1>
                <div className="flex gap-2 justify-between">
                    <p className='mt-0'>Classe </p>
                    <p className='font-bold'>{eleve.classe.libelle}</p>
                    <p className='mt-0'>القسم</p>
                </div>
                <div className="flex justify-between">
                    <p>Matricule </p>
                    <p className='font-bold'>{eleve.matricule}</p>
                    <p>رقم التسجيل</p>
                </div>
            </div>
        </div>
    )
}

export default StudentComponent