import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import { BsCalendar2Range, BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import Loading from '../../components/Loading';
import { useStateContext } from '../../contexts/ContextProvider';
import { AiTwotoneEdit } from 'react-icons/ai';
import ActionButton from '../../components/ActionButton';
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { IoIosSearch } from 'react-icons/io';
import Select from 'react-select';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaEdit, FaRegFileExcel } from 'react-icons/fa';
import AddEmploie from './AddEmploie';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Alert from '../../components/Alert';
import EditEmploie from './EditEmploie';
import { LuMousePointerClick } from 'react-icons/lu';
import AddAbsence from '../absences/AddAbsence';
import TabHead from '../../components/TabHead';
import MySelect from '../../components/MySelect';

const Emploies = () => {
  const navigate = useNavigate();
  const [emploies, setEmploies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const { setToken, user } = useStateContext();
  const [classes, setClasses] = useState([]);
  const [selectedClasse, setSelectedClasse] = useState(null);
  const [inputValueClasse, setValueClasse] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const [selectedEmploie, setSelectedEmploie] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [showAddAbsence, setShowAddAbsence] = useState(false);
  const tableRef = useRef(null);
  const [req, setReq] = useState({
    exp: '',
    classe: '',
  });

  const closeAdd = () => {
    setShowAdd(false);
  }

  const closeEdit = () => {
    setShowEdit(false);
    setSelectedEmploie(null);
  }

  const getClasses = () => {
    axiosClient.get('/classes')
      .then((data) => {
        setClasses(data.data.classes);
      }).catch(err => {
        const response = err.response;
        if (err.response.status === 401) {
          setToken(null);
        } else {
          if (response.status === 401) {
            setToken(null);
          }
          else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }
        }
      })
  }
  const handleInputChangeClasse = value => {
    setValueClasse(value);
  };

  // handle selection


  // handle selection
  const handleChangeClasse = value => {
    setSelectedClasse(value);
    setReq({ ...req, classe: value.id });
  }
  const getEmploies = () => {
    setLoading(true);
    axiosClient.get('/epmloies?exp=' + req.exp + '&classe=' + req.classe)
      .then((data) => {
        setEmploies(data.data.emploies);
        setLoading(false);
        setErrors([]);
      }).catch(err => {
        const response = err.response;
        if (err.response.status === 401) {
          setToken(null);
        } else {
          if (response.status === 422) {
            setErrors(response.data.errors);
          }
          else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }
        }
        setLoading(false);
      })
  }

  const supprimer = (emploie) => {
    Swal.fire({
      title: "Confirmation",
      text: "Etes vous sûre de supprimer cette element ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2196F3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui!",
      cancelButtonText: "Non!"
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient.delete('/emploie/' + emploie.id)
          .then(response => {
            toast.success('Opération effectuée avec succès!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            getEmploies();
          }).catch(err => {
            const response = err.response;
            if (err.response.status === 401) {
              setToken(null);
            } else {
              if (response.status === 401) {
                setToken(null);
              }
              else {
                if (response.status === 403) {
                  navigate('/403');
                } else {
                  if (response.status === 404) {
                    navigate('/404');
                  }
                  else {
                    navigate('/500');
                  }
                }
              }
            }

          })
      }
    });
  }

  const closeAbsence = () => {
    setShowAddAbsence(false);
    setSelectedEmploie(null);
  }


  useEffect(() => {
    getClasses();
  }, []);
  return (
    <>
      <Header icon={<BsCalendar2Range />} category='Page' title='Emploies' titleAr={"الجداول الزمنية"} />
      {errors &&
        Object.keys(errors).map(key => (
          <Alert key={key} message={errors[key][0]} />
        ))
      }
      <div className='flex-col '>
        <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between'>
          {
            user.role && (user.role.name === 'dretude' || user.role.name === 'admin') ?

              <div className='items-end mt-4'>
                <Button onClick={() => { setShowAdd(true) }} color="bg-blue-500" icon={<BsPlusCircleFill />} size="20" text="Ajouter" textColor="text-white" />
              </div>
              : <></>
          }

          <div className="flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-2">
            <MySelect label="Classe" placeholder="Classe..." cacheOptions defaultOptions value={selectedClasse} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={classes} onInputChange={handleInputChangeClasse} onChange={handleChangeClasse} />
            <Input label="Expression" labelAr="وصف" type="text" value={req.exp} onChange={ev => setReq({ ...req, exp: ev.target.value })} />
            <div className='mt-8'>
              <Button onClick={() => getEmploies()} color="bg-yellow-500" icon={<IoIosSearch />} size="20" text="Chercher" textColor="text-white" />
            </div>
            <div className="mt-8">
              <DownloadTableExcel
                filename="Inscriptions"
                sheet="inscriptions"
                currentTableRef={tableRef.current}
              >
                <Button color="bg-green-500" icon={<FaRegFileExcel />} size="20" text="Exporter" textColor="text-white" />
              </DownloadTableExcel>
            </div>
          </div>
        </div>

        <div className='mt-3 w-full overflow-x-auto'>
          <table ref={tableRef} className='table-auto'>
            <thead>
              <tr>
                <th><TabHead left="Classe" right="القسم" /></th>
                <th><TabHead left="Jour" right="التاريخ" /></th>
                <th><TabHead left="Matière" right="المادة" /></th>
                <th><TabHead left="Heure Debut" right="بداية الوقت" /> </th>
                <th><TabHead left="Heure Fin" right="نهاية الوقت" /> </th>
                <th><TabHead left="Enseignant" right="المصحح" /></th>
                <th><TabHead left="Actions" right={"إجراء"}/></th>
              </tr>
            </thead>
            {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="9"><Loading /></td></tr></tbody> :
              (

                <tbody>
                  {emploies && emploies.map(o => (
                    <tr key={o.id}>
                      <td>{o.classe.libelle}</td>
                      <td>{o.jour.libelleFr}</td>
                      <td>{o.matiere.matiere.libelle}</td>
                      <td>{o.heureDebut}</td>
                      <td>{o.heureFin}</td>
                      <td>{o.enseignant.nom}</td>
                      <td>
                        <div className="flex justify-center w-full gap-2 items-center">
                          <ActionButton onClick={() => {
                            setSelectedEmploie(o);
                            setShowAddAbsence(true);
                          }} color="bg-red-500" icon={<LuMousePointerClick />} textColor="text-white" title="Relever Absences" />
                          <ActionButton onClick={() => {
                            setSelectedEmploie(o);
                            setShowEdit(true);
                          }} color="bg-blue-500" icon={<FaEdit />} textColor="text-white" title="Modifier" />
                          <ActionButton onClick={() => supprimer(o)} color="bg-red-500" icon={<BsTrash />} textColor="text-white" title="Annuler" />
                        </div>
                      </td>
                    </tr>
                  ))

                  }
                </tbody>
              )
            }
          </table>
        </div>
        {
          showAdd ? <AddEmploie onClose={closeAdd} /> : <></>
        }
        {
          showEdit ? <EditEmploie selectedEmploie={selectedEmploie} onClose={closeEdit} /> : <></>
        }
        {
          showAddAbsence ? <AddAbsence selectedEmploie={selectedEmploie} onClose={closeAbsence} /> : <></>
        }
      </div>
    </>
  )
}

export default Emploies