import React from 'react'
import { useStateContext } from '../contexts/ContextProvider';

const StudentMenuComponent = ({ text, textAr, colors, onclick, icon }) => {
  const { setToken, user, } = useStateContext();
  return (
    <div className={"flex gap-2 w-full my-2 border px-4 py-3 rounded relative hover:cursor-pointer " + colors} onClick={() => onclick()}>
      {
        user.langue === "AR" ?
          <>
            <div className=" flex w-full text-lg font-bold justify-end">
              <span>{textAr}</span>
            </div>
            <div className=" text-3xl">
              {icon}
            </div>
          </>
          :
          <>
            <div className=" text-3xl">
              {icon}
            </div>
            <div className=" flex w-full text-lg font-bold ">
              <span>{text}</span>
            </div>
          </>
      }
    </div>
  )
}

export default StudentMenuComponent