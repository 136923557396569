import React from 'react'
import { useStateContext } from '../contexts/ContextProvider';

const MyLabel = ({label,content,labelAr,cls}) => {
  const { setToken, user, } = useStateContext();
  return (
    <div className={cls}>
        <span>{label}</span>
        <span>{content}</span>
        <span>{labelAr}</span>
    </div>
  )
}
export default MyLabel