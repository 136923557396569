import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import Alert from '../../components/Alert'
import { IoIosSearch } from 'react-icons/io';

import { useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import { useStateContext } from '../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaRegFileExcel } from 'react-icons/fa6';
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import Loading from '../../components/Loading';
import ActionButton from '../../components/ActionButton';
import Swal from 'sweetalert2';
import { CiInboxOut } from 'react-icons/ci';
import studentlogo from '../../data/studentlogo.png';
import { PiStudentBold } from 'react-icons/pi';
import StudentComponent from '../../components/StudentComponent';
import StudentMenu from './StudentMenu';

const MesEleves = () => {
    const [errors, setErrors] = useState(null);

    const [loading, setLoading] = useState(false);
    const [eleves, setEleves] = useState([]);
    const[selectedEleve,setSelectedEleve]=useState(null);
    const [showMenu, setShowMenu] = useState(false);
    const { token, setToken, user } = useStateContext();
    const tableRef = useRef();
    const [req, setReq] = useState({
        DateDebut: '',
        DateFin: '',
        exp: '',
    });
    const navigate = useNavigate();
    useEffect(() => {
        getEleves();
    }, []);

    const closeMenu = () => {
        setShowMenu(false);
        setSelectedEleve(null);
    }
    const getEleves = () => {
        setLoading(true);
        axiosClient.get('/mesenfants')
            .then(response => {
                setEleves(response.data.eleves);
                setLoading(false);
                setErrors(null);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }




    return (
        <>
            <Header icon={<PiStudentBold />} title="Élèves" titleAr="الأبناء" />
            {errors &&
                Object.keys(errors).map(key => (
                    <Alert key={key} message={errors[key][0]} />
                ))
            }
            <div className="flex-col h-full gap-3">
                {eleves && eleves.map(o => (
                    <div className="" onClick={() => {
                        setSelectedEleve(o);
                        setShowMenu(true);
                    }}>
                        <StudentComponent eleve={o} />
                    </div>
                ))

                }

            </div>
            {
                showMenu ? <StudentMenu student={selectedEleve} onClose={closeMenu} /> : <></>
            }
        </>
    )
}

export default MesEleves