import React, { useEffect, useRef, useState } from 'react';
import { Button, Header, Input } from '../../components';
import Alert from '../../components/Alert';
import { IoCalendarOutline } from "react-icons/io5";
import { BsCalendar4Range } from "react-icons/bs";
import Loading from '../../components/Loading';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaRegCalendarDays, FaRegFileExcel } from 'react-icons/fa6';
import axiosClient from '../../axios-client';
import { useStateContext } from '../../contexts/ContextProvider';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactToPrint from 'react-to-print';
import { FaPrint } from 'react-icons/fa';
import Icon from '../../data/icon.png';
import Select from 'react-select';
import NumberFormat from '../../components/NumberFormat';
import Entete from '../../components/Entete';
import MySelect from '../../components/MySelect';

const Situation = () => {

  const [errors, setErrors] = useState(null);
  const [showJournal, setShowJournal] = useState(false);
  const [showBilan, setShowBilan] = useState(false);
  const [initial, setInitial] = useState(true);
  const [journal, setJournal] = useState([]);
  const [loadingJournal, setLoadingJournal] = useState(false);
  const [loadingBilan, setLoadingBilan] = useState(false);
  const journalRef = useRef();
  const bilanRef = useRef();
  const jounalPrintRef = useRef();
  const bilanPrintRef = useRef();
  const [caisses, setCaisses] = useState([]);
  const [selectedCaisse, setSelectedCaisse] = useState(null);
  const [inputValueCaisse, setValueCaisse] = useState('');

  const [total, setTotal] = useState({
    somme: 0,
    nombre: 0,
    caisse: '',
    start: '',
    end: '',
  });

  const [bilan, setBilan] = useState({
    depenses: [],
    totalEntree: 0,
    totalDepense: 0,
    totalSortie: 0,
    caisse: '',
    start: '',
    end: '',
  });


  const [req, setReq] = useState({
    DateDebut: '',
    DateFin: '',
    caisse: '',
  });
  const { setToken, user } = useStateContext();
  const navigate = useNavigate();

  const getJournal = () => {
    setInitial(true);

    setShowBilan(false);
    setLoadingBilan(false);
    setInitial(false);
    setShowJournal(true);
    setLoadingJournal(true);
    setErrors(null);
    axiosClient.get('/journals?DateDebut=' + req.DateDebut + '&DateFin=' + req.DateFin + '&caisse=' + req.caisse)
      .then((response) => {
        setJournal(response.data.journal);
        setLoadingJournal(false);
        setShowJournal(true);
        setTotal({ ...total, somme: response.data.total, nombre: response.data.nombre, caisse: response.data.caisse, start: response.data.start, end: response.data.end })
      }).catch(err => {
        const response = err.response;
        if (response.status === 401) {
          setToken(null);
        }
        else {
          if (response.status === 422) {
            setErrors(response.data.errors);
            setLoadingJournal(false);
            toast.error('Une erreur est survenue!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }

        }
      })

  }

  const getBilan = () => {
    setInitial(true);
    setLoadingJournal(false);
    setShowJournal(false);
    setInitial(false);
    setShowBilan(true);
    setLoadingBilan(true);
    setErrors(null);
    axiosClient.get('/bilan?DateDebut=' + req.DateDebut + '&DateFin=' + req.DateFin + '&caisse=' + req.caisse)
      .then((response) => {
        setBilan({ ...bilan, depenses: response.data.depenses, totalDepense: parseFloat(response.data.depenses.reduce((n, { montant }) => n + parseFloat(montant), 0)), totalEntree: response.data.totalEntree, totalSortie: response.data.totalSortie, caisse: response.data.caisse, start: response.data.start, end: response.data.end });
        setLoadingBilan(false);
      }).catch(err => {
        const response = err.response;
        if (response.status === 401) {
          setToken(null);
        }
        else {
          if (response.status === 422) {
            setErrors(response.data.errors);
            setLoadingBilan(false);
            toast.error('Une erreur est survenue!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }

        }
      })

  }

  useEffect(() => {
    if (user.role && (user.role.name === 'admin')) {
      getCaisses();
    }
  }, []);

  const handleInputChangeCaisse = value => {
    setValueCaisse(value);
  };

  // handle selection


  // handle selection
  const handleChangeCaisse = value => {
    setSelectedCaisse(value);
    setReq({ ...req, caisse: value.id });

  }


  const getCaisses = () => {
    axiosClient.get('/caisses')
      .then((data) => {
        setCaisses(data.data.caisses);
      }).catch(err => {
        const response = err.response;
        if (response.status === 401) {
          setToken(null);
        }
        else {
          if (response.status === 422) {
            setErrors(response.data.errors);
            toast.error('Une erreur est survenue!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }

        }
      })
  }


  return (
    <>
      <Header icon={<FaRegCalendarDays />} title="Situations" titleAr={"وضعية الصندوق"} />
      {errors &&
        Object.keys(errors).map(key => (
          <Alert key={key} message={errors[key][0]} />
        ))
      }
      <div className='flex gap-2 w-full flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between items-center'>
        <Input label="Date debut"  labelAr="من" type="date" value={req.DateDebut} onChange={ev => setReq({ ...req, DateDebut: ev.target.value })} required="required" />
        <Input label="Date fin" labelAr="الى" type="date" value={req.DateFin} onChange={ev => setReq({ ...req, DateFin: ev.target.value })} required="required" />
        {user.role && (user.role.name === 'admin') ?
          <>

            <div className="flex-col w-full">
            <MySelect label={"Caisse"} labelAr={"الصندوق"} placeholder="Caisse" cacheOptions defaultOptions value={selectedCaisse} getOptionLabel={e => e.nom} getOptionValue={e => e.id} options={caisses} onInputChange={handleInputChangeCaisse} onChange={handleChangeCaisse} />
            </div>
          </>
          :
          <></>

        }
        <div className='w-full mt-8'>
          <Button onClick={() => getJournal()} color="bg-yellow-500" icon={<IoCalendarOutline />} size="20" text="Journal" textColor="text-white" />
        </div>
        <div className='w-full mt-8'>
          <Button onClick={() => getBilan()} color="bg-green-500" icon={<BsCalendar4Range />} size="20" text="Bilan" textColor="text-white" />
        </div>
      </div>
      <div className='flex flex-col gap-4 mt-4'>
        {initial ? <></> :

          <>
            {showJournal ?
              <>
                {loadingJournal ? <Loading /> : <>
                  <div className="flex justify-end items-end gap-2">
                    <ReactToPrint
                      trigger={() => {
                        return <Button icon={<FaPrint />} color="ml-1 bg-blue-500" textColor="text-white" text="Imprimer" />
                      }}
                      content={() => jounalPrintRef.current}
                      documentTitle='Journal'
                      pageStyle='journal'
                    />
                    <DownloadTableExcel
                      filename="Journal"
                      sheet="journal"
                      currentTableRef={journalRef.current}
                    >
                      <Button color="bg-green-500" icon={<FaRegFileExcel />} size="20" text="Exporter" textColor="text-white" />

                    </DownloadTableExcel>
                  </div>
                  <div className='mt-3 w-full overflow-x-auto'>
                    <div className='p-2' ref={jounalPrintRef}>
                      <table className='table-auto'>
                        <thead>
                          <tr>
                            <td>
                              <Entete />
                              <div className="flex justify-center items-center">
                                <h1>Journal Caisse {total.caisse} du: {total.start} au: {total.end}</h1>
                              </div>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <div className='my-2'>
                            <h2 className='text-lg font-bold'>Total= <NumberFormat valeur={total.somme} /> MRU ({total.nombre})</h2>
                          </div>
                          <table ref={journalRef} className='table-auto'>
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Elève</th>
                                <th>Classe</th>
                                <th>Montant</th>
                                <th>M.Paiement</th>
                              </tr>
                            </thead>
                            <tbody>
                              {journal && journal.map(o => (
                                <tr key={o.id}>
                                  <td>{o.datePaiement}</td>
                                  <td>{o.inscription.eleve}</td>
                                  <td>{o.inscription.classe.libelle}</td>
                                  <td><NumberFormat valeur={o.montant} /></td>
                                  <td>{o.typePaiement}</td>
                                </tr>
                              ))

                              }
                            </tbody>
                          </table>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>

                }
              </>
              :
              <></>
            }
            {showBilan ?
              <>
                {loadingBilan ? <Loading /> :
                  <>
                    <div className="flex justify-end items-end gap-2">
                      <ReactToPrint
                        trigger={() => {
                          return <Button icon={<FaPrint />} color="ml-1 bg-blue-500" textColor="text-white" text="Imprimer" />
                        }}
                        content={() => bilanPrintRef.current}
                        documentTitle='Bilan'
                        pageStyle='bilan'
                      />

                    </div>
                    <div className="mt-3 w-full overflow-x-auto">
                      <div ref={bilanPrintRef} className="p-3">
                        <table className='table-auto'>
                          <thead>
                            <tr>
                              <td>
                                <Entete />
                                <div className="flex justify-center items-center">
                                  <h1>Bilan Caisse {bilan.caisse} du: {bilan.start} au: {bilan.end}</h1>
                                </div>
                              </td>
                            </tr>
                          </thead>
                          <tbody>

                            <table ref={bilanRef} className='table-auto'>
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Beneficiaire</th>
                                  <th>Categorie</th>
                                  <th>Montant</th>
                                </tr>
                              </thead>
                              <tbody>
                                {bilan.depenses && bilan.depenses.map(o => (
                                  <tr>
                                    <td>{o.dateDepense}</td>
                                    <td>{o.beneficiaire}</td>
                                    <td>{o.categorie}</td>
                                    <td><NumberFormat valeur={o.montant} /></td>
                                  </tr>
                                ))

                                }
                                <tr className='bg-gray-100 font-bold'>
                                  <td colSpan="3">Total dépenses</td>
                                  <td><NumberFormat valeur={bilan.totalDepense} /></td>
                                </tr>
                                <tr className='font-bold'>
                                  <td colSpan="3">Total entrées</td>
                                  <td><NumberFormat valeur={bilan.totalEntree} /></td>
                                </tr>
                                <tr className='font-bold'>
                                  <td colSpan="3">Total sorties</td>
                                  <td><NumberFormat valeur={bilan.totalSortie} /></td>
                                </tr>
                                <tr className='bg-gray-100 font-bold'>
                                  <td colSpan="3">Caisse</td>
                                  <td><NumberFormat valeur={bilan.totalEntree - bilan.totalDepense - bilan.totalSortie} /></td>
                                </tr>
                              </tbody>
                            </table>
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </>
                }

              </>
              :
              <></>

            }

          </>

        }
      </div>
    </>
  )
}

export default Situation