import React, { useEffect, useState } from 'react'
import { Button, Header } from '../../components'
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import Loading from '../../components/Loading';
import { useStateContext } from '../../contexts/ContextProvider';
import { AiTwotoneEdit } from 'react-icons/ai';
import ActionButton from '../../components/ActionButton';
import AddMatiere from './AddMatiere';
import { TbMath } from 'react-icons/tb';
import TabHead from '../../components/TabHead';


const Matieres = () => {
    const navigate = useNavigate();
    const [matieres, setMatieres] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showAdd, setShowAdd] = useState(false);
    const { setToken } = useStateContext();


    const getMatieres = () => {
        
        axiosClient.get('/matieres')
          .then((data) => {
            setMatieres(data.data.matieres);
            setLoading(false);
            
          }).catch(err => {
            const response=err.response;
            if (err.response.status === 401) {
              setToken(null);
            }else{
                if(response.status===401)
                {
                  setToken(null);
                }
                else{
                  if(response.status===403){
                    navigate('/403');
                  }else{
                    if(response.status===404){
                      navigate('/404');
                    }
                    else{
                      navigate('/500');
                    }
                  }
                }
              }
    
          })
      }
    
      useEffect(() => {
        getMatieres();
      }, []);

      const closeAdd=()=>{
        setShowAdd(false);
        setLoading(true);
        getMatieres();
      }
  return (
    <>
      <Header icon={<TbMath />} category='Page' title='Matieres' titleAr={"المــواد"} />
      <div className='flex-col '>
        <div className='items-end'>
          
            <Button onClick={()=>setShowAdd(true)} color="bg-blue-500" icon={<BsPlusCircleFill />} size="20" text="Ajouter" textColor="text-white" />
          
        </div>
        <div className='mt-3 w-full overflow-x-auto'>
          {loading ? <Loading /> :
            (
              <table className='table-auto'>
                <thead>
                  <tr>
                    <th ><TabHead left="Code" right="الرمز"/></th>
                    <th><TabHead left="Libelle" right="الإسم"/></th>
                    <th><TabHead left="Langue" right="لغة التدريس"/></th>
                    
                    <th><TabHead left="Actions" right={"إجراء"}/></th>
                  </tr>
                </thead>
                <tbody>
                  {matieres && matieres.map(o => (
                    <tr key={o.id}>
                      <td>{o.code}</td>
                      <td>{o.libelle}</td>
                      <td>{o.langue}</td>
                      <td>
                        <div className="flex justify-evenly">
                          
                        </div>
                      </td>
                    </tr>
                  ))

                  }
                </tbody>
              </table>
            )

          }
        </div>
        
      </div>
      <AddMatiere isVisible={showAdd} onClose={closeAdd}/> 
    </>
  )
}

export default Matieres