import React, { useEffect, useState } from 'react'
import { Button, Header } from '../../components'
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import Loading from '../../components/Loading';
import { useStateContext } from '../../contexts/ContextProvider';
import { AiTwotoneEdit } from 'react-icons/ai';
import ActionButton from '../../components/ActionButton';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import Swal from 'sweetalert2';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import { FiUsers } from 'react-icons/fi';
import TabHead from '../../components/TabHead';
const Users = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setToken } = useStateContext();
  const [errors, setErrors] = useState(null);
  const getUsers = () => {
    axiosClient.get('/users')
      .then((data) => {
        setUsers(data.data.users);
        setLoading(false);

      }).catch(err => {
        const response = err.response;
        if (err.response.status === 401) {
          setToken(null);
        } else {
          if (response.status === 401) {
            setToken(null);
          }
          else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }
        }

      })
  }

  const disable = (user) => {
    Swal.fire({
      title: "Confirmation",
      text: "Êtes vous sûre de bloquer cet utilisateur?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2196F3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui!",
      cancelButtonText: "Non!"
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient.put('/user/disable/' + user.id)
          .then(response => {
            toast.success('Opération effectuée avec succès!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            getUsers();
          }).catch(err => {
            const response = err.response;
            if (err.response.status === 401) {
              setToken(null);
            } else {
              if (response.status === 422) {
                setErrors(response.data.errors);

                toast.error('Une erreur est survenue!', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
              else {
                if (response.status === 403) {
                  navigate('/403');
                } else {
                  if (response.status === 404) {
                    navigate('/404');
                  }
                  else {
                    navigate('/500');
                  }
                }
              }
            }

          })
      }
    });
  }

  const enable = (user) => {
    Swal.fire({
      title: "Confirmation",
      text: "Êtes vous sûre de débloquer cet utilisateur?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2196F3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui!",
      cancelButtonText: "Non!"
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient.put('/user/enable/' + user.id)
          .then(response => {
            toast.success('Opération effectuée avec succès!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            getUsers();
          }).catch(err => {
            const response = err.response;
            if (err.response.status === 401) {
              setToken(null);
            } else {
              if (response.status === 422) {
                setErrors(response.data.errors);

                toast.error('Une erreur est survenue!', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
              else {
                if (response.status === 403) {
                  navigate('/403');
                } else {
                  if (response.status === 404) {
                    navigate('/404');
                  }
                  else {
                    navigate('/500');
                  }
                }
              }
            }

          })
      }
    });
  }
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Header icon={<FiUsers />} category='Page' title='Utilisateurs' titleAr={"المستخدمين"} />
      <div className='flex-col '>
        {errors &&
          Object.keys(errors).map(key => (
            <Alert key={key} message={errors[key][0]} />
          ))
        }
        <div className='items-end'>
          <Link to='/user/new'>
            <Button color="bg-blue-500" icon={<BsPlusCircleFill />} size="20" text="Ajouter" textColor="text-white" />
          </Link>
        </div>
        <div className='mt-3 w-full overflow-x-auto'>
          {loading ? <Loading /> :
            (
              <table className='table-auto'>
                <thead>
                  <tr>
                    <th><TabHead left="Username (Tel)" right={" اسم المستخدم (رقم الهاتف)"} /></th>
                    <th><TabHead left="Nom"right={"الإسم الكامل"}/></th>
                    <th><TabHead left="Role" right={"الوظيفة"}/></th>
                    <th><TabHead left="Actions" right={"إجراء"}/></th>
                  </tr>
                </thead>
                <tbody>
                  {users && users.map(o => (
                    <tr key={o.id}>
                      <td>{o.username}</td>
                      <td>{o.nom}</td>
                      <td>{o.role && o.role.name}</td>
                      <td>
                        <div className="flex justify-evenly">
                          <Link to={`/user/${o.id}`}>
                            <ActionButton color="bg-green-500" icon={<AiTwotoneEdit />} textColor="text-white" title="Modifier" />
                          </Link>
                          {o.active === true ? <ActionButton onClick={() => {
                            disable(o);
                          }} color="bg-red-500" icon={<FaLock />} textColor="text-white" title="Bloquer" />
                            :
                            <ActionButton onClick={() => {
                              enable(o);
                            }} color="bg-blue-500" icon={<FaLockOpen />} textColor="text-white" title="Debloquer" />

                          }
                        </div>
                      </td>
                    </tr>
                  ))

                  }
                </tbody>
              </table>
            )

          }
        </div>
      </div>
    </>
  )
}

export default Users