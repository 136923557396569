import React from 'react'
import { NavLink } from 'react-router-dom'
import { useStateContext } from '../contexts/ContextProvider';

const NavComp = ({  activeLink, normalLink, currentColor, icon,label,labelAr,link }) => {
    const { activeMenu, setActiveMenu, screenSize, user } = useStateContext();
    const handleCloseSidebar = () => {
        if (screenSize <= 900) {
          setActiveMenu(false);
        }
      }
    return (
        <div>
            <NavLink to={link} key={link}  onClick={handleCloseSidebar}
                className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                +" mx-1"} style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                
                {
                    user.langue === 'AR' ?
                        <div className="flex mr-2 gap-2 w-full justify-end">
                            <span className='capitalize mb-2 font-bold'>
                                {labelAr}
                            </span>
                            {icon}
                        </div>
                        :
                        <div className="flex gap-2">
                            {icon}
                            <span className='capitalize mb-2 '>
                                {label}
                            </span>
                        </div>
                }
            </NavLink>
        </div>
    )
}

export default NavComp