import React from 'react'
import { Bs7Circle, BsCalendar2Range, BsFillBuildingFill } from 'react-icons/bs'
import { FaExclamation, FaRegFileAlt, FaRegMoneyBillAlt } from 'react-icons/fa'
import { FiShoppingCart, FiUsers } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import { TbMath } from "react-icons/tb";
import { useStateContext } from '../../contexts/ContextProvider'
import { LuFileSpreadsheet, LuMousePointerClick } from 'react-icons/lu'
import { PiNotePencilBold, PiStudentBold } from 'react-icons/pi'
import { FaRegCalendarDays } from 'react-icons/fa6'
import { BiArrowToTop } from "react-icons/bi";
import { MdEditNote, MdManageHistory } from "react-icons/md";
import { CiInboxOut } from "react-icons/ci";
import NavComp from '../NavComp'

const AdminRole = () => {
  const activeLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg text-white bg-blue-600 text-md m-2';
  const normalLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg  text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
  const { setActiveMenu, screenSize, currentColor } = useStateContext();


  const handleCloseSidebar = () => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    }
  }
    return (
        < >
            <div>
                <NavComp link={`/users`}   label="Utilisateur" labelAr="المستخدمين" icon={<FiUsers/>} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/classes`}   label="Classes" labelAr="الأقسام" icon={<BsFillBuildingFill />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/inscriptions`}   label="Inscriptions" labelAr="التسجيلات" icon={<PiStudentBold />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/absences`}   label="Absences" labelAr="الغيابات" icon={<LuMousePointerClick />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/observations`}   label="Observations" labelAr="الملاحظـات" icon={<FaExclamation />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/factures`}   label="Factures" labelAr="الفواتــيــر" icon={<LuFileSpreadsheet />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/depenses`}   label="Dépenses" labelAr="المصاريف" icon={<FiShoppingCart />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/alimentations`}   label="Alimentations" labelAr="المداخيل الأخرى" icon={<BiArrowToTop />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/Sorties`}   label="Alimentations" labelAr="المخاريج" icon={<CiInboxOut />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/situations`}   label="Situations" labelAr="وضعية الصندوق" icon={<FaRegCalendarDays />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/examens`}   label="Examens" labelAr="الإمـتحـانـات" icon={<MdEditNote />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/devoirs`}   label="Devoirs" labelAr="الإختـبـارات" icon={<PiNotePencilBold />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/emploies`}   label="Emploies" labelAr="الجدول الزمني" icon={<BsCalendar2Range />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/coefficients`}   label="Coefficients" labelAr="الضوارب و التنقيط" icon={<Bs7Circle />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/bulletins`}   label="Bulletins" labelAr="كشوف الدرجات" icon={<FaRegFileAlt />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/matieres`}   label="Matières" labelAr="المــواد" icon={<TbMath />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/historiques`}   label="Historiques" labelAr="أرشيف" icon={<MdManageHistory />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
            </div>
        </>
    )
}
export default AdminRole