import React from 'react'
import { useStateContext } from '../contexts/ContextProvider';

const TabHead = ({ left, right }) => {
  const { setToken, user, } = useStateContext();
  return (
    <div className="flex flex-col text-sm justify-center">
      {
        user.langue === 'FR' ?
          <span>{left}</span>
          :
          user.langue === 'AR' ?
            <div className="flex w-full justify-center items-center">
              <span>{right}</span>
            </div>
            :
            <>
              <span>{right}</span>
              <span>{left}</span>
            </>
      }
    </div>
  )
}

export default TabHead