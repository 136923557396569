import React from 'react'
import { MultiSelect } from 'react-multi-select-component'

const SelectMulti = ({ options, selected, setSelected, label, labelAr, required }) => {
  return (
    <div className='flex-col w-full' >
      <div className="flex">
        <label className="block text-sm font-medium leading-6  text-gray-900">{label}{required && <span className='text-red-500'> *</span>}</label>
        <label className="block text-sm font-medium leading-6  text-gray-900">{required && <span className='text-red-500'> *</span>}{labelAr}</label>
      </div>
      <MultiSelect className='mt-2 '
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy="Choisir"
      />
    </div>
  )
}

export default SelectMulti