import React from 'react'
import { BsFillBuildingFill } from 'react-icons/bs'
import { FaExclamation, FaRegMoneyBillAlt } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import { useStateContext } from '../../contexts/ContextProvider'
import { BiTransferAlt } from 'react-icons/bi'
import { Bs7Circle } from "react-icons/bs";
import { MdEditNote } from "react-icons/md";
import { PiNotePencilBold, PiStudentBold } from "react-icons/pi";
import { BsCalendar2Range } from "react-icons/bs";
import { FaRegFileAlt } from "react-icons/fa";
import { LuMousePointerClick } from 'react-icons/lu'
import NavComp from '../NavComp'
import { TbMath } from 'react-icons/tb'

const DrEtudeRole = () => {
    const activeLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg text-white bg-blue-600 text-md m-2';
    const normalLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg  text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
    const { setActiveMenu, screenSize, currentColor } = useStateContext();


    const handleCloseSidebar = () => {
        if (screenSize <= 900) {
            setActiveMenu(false);

        }
    }
    return (
        <div >

            <div>
                <NavComp link={`/classes`}  label="Classes" labelAr="الأقسام" icon={<BsFillBuildingFill />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor} />
                <NavComp link={`/inscriptions`}  label="Inscriptions" labelAr="التسجيلات" icon={<PiStudentBold />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor} />
                <NavComp link={`/absences`}  label="Absences" labelAr="الغيابات" icon={<LuMousePointerClick />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor} />
                <NavComp link={`/observations`}  label="Observations" labelAr="الملاحظـات" icon={<FaExclamation />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor} />
                <NavComp link={`/examens`}   label="Examens" labelAr="الإمـتحـانـات" icon={<MdEditNote />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/devoirs`}   label="Devoirs" labelAr="الإختـبـارات" icon={<PiNotePencilBold />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/emploies`}   label="Emploies" labelAr="الجدول الزمني" icon={<BsCalendar2Range />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/coefficients`}   label="Coefficients" labelAr="الضوارب و التنقيط" icon={<Bs7Circle />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/bulletins`}   label="Bulletins" labelAr="كشوف الدرجات" icon={<FaRegFileAlt />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                
            </div>
        </div>
    )
}

export default DrEtudeRole