import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { GiCancel } from 'react-icons/gi';
import Textarea from '../../components/Textarea';

const VoirHistorique = ({ isVisible = true, onClose, historique, }) => {
    const [loading, setLoading] = useState(false);
    if (!isVisible) {
        return null
    }
    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col w-1/2 bg-white lg:p-10 xl:p-10  p-2 md:p-10  rounded-xl'>
                        <div className=''>
                            <Header title="Détails Historique" titleAr={"أرشيف"} />
                        </div>
                        {loading ? <Loading /> :
                            <>
                                <div className='flex flex-wrap w-full md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-3'>
                                    <div className='flex-col w-1/2  justify-evenly '>
                                        <div className='mr-2'>
                                            <Input label="Date historique" readOnly="readOnly" type="text" value={historique.dateHistorique} required="required" />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Table" readOnly="readOnly" type="text" value={historique.table} required="required" />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Type d'action" readOnly="readOnly" type="text" value={historique.action} required="required" />
                                        </div>
                                        <div className='mr-2'>
                                            <Input label="Utilisateur" readOnly="readOnly" type="text" value={historique.user}  />
                                        </div>
                                        
                                    </div> 
                                    <div className="flex w-1/2 flex-col">
                                    <Textarea rows={6} label="Pre-Valeur" value={historique.preValeur}/>
                                        <Textarea rows={6} label="Post-Valeur" value={historique.postValeur}/>
                                    </div>                                  
                                </div>
                            </>
                        }                       
                        <div className='flex  justify-end items-end'>
                            <div className=' flex gap-3 m-2  justify-between'>
                                <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VoirHistorique