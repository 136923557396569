import React from 'react'

import Icon from '../data/icon.png';
const Entete = () => {
    return (
        <div className="flex justify-between gap-3">
            <div className="flex flex-col gap-3">
                <h1>Ecole privées Rouwad El Maarif</h1>
                <h1>Jardin-Primaire-Collège-Secondaire</h1>
                <h2>Tel: 44449696 - 32196666</h2>
            </div>
            <img className="mx-auto h-28 w-auto" src={Icon} alt="Your Company" />
            <div className="flex flex-col text-right gap-3">
                <h1>مدارس   رواد المـعارف الحرة</h1>
                <h1> روضة - ابتدائية - إعدادية - ثانوية </h1>
                <h2> 44449696 - 32196666: الهاتف</h2>
            </div>
        </div>
    )
}

export default Entete