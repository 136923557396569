import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../../axios-client';
import Loading from '../../components/Loading';
import { useStateContext } from '../../contexts/ContextProvider';
import ActionButton from '../../components/ActionButton';
import Select from 'react-select';
import { FaCheckCircle, FaEdit, FaEye, FaPrint, FaRegFileExcel } from 'react-icons/fa';

import Alert from '../../components/Alert';

import { GiCancel } from 'react-icons/gi';
import { FaFeatherPointed } from 'react-icons/fa6';
import { MdEditNote } from 'react-icons/md';
import { RiEmotionHappyFill, RiEmotionUnhappyFill } from 'react-icons/ri';
import ReactToPrint from 'react-to-print';
import Entete from '../../components/Entete';
import { PiStudentBold } from 'react-icons/pi';
import TabHead from '../../components/TabHead';

const FicheNoteExamen = ({ selectedExamen, onClose }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState(null);
    const { setToken, user } = useStateContext();



    const [eleves, setEleves] = useState([]);

    const tableRef = useRef(null);


    const componentRef = useRef();
    const getEleves = () => {
        axiosClient.get('/eleves/' + selectedExamen.classe.id)
            .then((data) => {
                setEleves(data.data.eleves);
                setLoading(false);
            }).catch(err => {
                navigate('/500');
            })
    }

    useEffect(() => {
        getEleves();
    }, []);
    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col max-h-screen overflow-y-auto bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto md:w-1/2 lg:w-1/2 xl:w-1/2 rounded-xl'>
                        <Header icon={<PiStudentBold />} category='Page' title={'Fiche des notes du examen ' + selectedExamen.classe.libelle + ' ' + selectedExamen.matiereLibelle + ' / ' + selectedExamen.trimestre.code} titleAr={selectedExamen.classe.libelle + ' ' + selectedExamen.matiereLibelle + ' / ' + selectedExamen.trimestre.code+' : إستمارة النتائح للإمتحان'}/>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }
                        <div className="flex-col">
                            <div className="flex w-full gap-2 justify-end items-end">
                                <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                                <ReactToPrint
                                    trigger={() => {
                                        return <Button icon={<FaPrint />} color="ml-1 bg-green-500" textColor="text-white" text="Imprimer" />
                                    }}
                                    content={() => componentRef.current}
                                    documentTitle='Liste des Élèves'
                                    pageStyle='print'
                                />
                            </div>
                            <div className="flex" ref={componentRef}>
                                <table className='table-auto'>
                                    <thead>
                                        <tr>
                                            <td>
                                                <Entete />
                                                <div className="flex justify-between items-center">
                                                    <h1>Fiche des notes du examen:</h1>
                                                    <h1 className='text-sm font-bold' >{selectedExamen.classe.libelle + ' ' + selectedExamen.matiereLibelle + ' / ' + selectedExamen.trimestre.code}</h1>
                                                    <h1> إستمارة النتائح للإمتحان</h1>
                                                </div>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <table className='table-auto '>
                                            <thead className='border border-gray-800'>
                                                <tr className='border border-gray-800'>
                                                    <th className='border border-gray-800'><TabHead left="Matricule" right="رقم التسجيل"/> </th>
                                                    <th className='border border-gray-800'><TabHead left="Elève" right="الطالب" /></th>
                                                    <th className='border border-gray-800'><TabHead left="Note" right="النتيجة" /></th>
                                                </tr>
                                            </thead>
                                            {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="6"><Loading /></td></tr></tbody> :
                                                <tbody>
                                                    {eleves && eleves.map(o => (
                                                        <tr className='border border-gray-800' key={o.id}>
                                                            <td className='border border-gray-800'>{o.matricule}</td>
                                                            <td className='border border-gray-800'>{o.eleve}</td>
                                                            <td className='border justify-end border-gray-800'><span className='text-sm text-gray-300'>......</span> /{selectedExamen.noteMax}</td>
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            }

                                        </table>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FicheNoteExamen