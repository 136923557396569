import React from 'react'
import { useStateContext } from '../contexts/ContextProvider';

const Header = ({ category, title, icon, titleAr }) => {
  const { setToken, user, } = useStateContext();
  return (
    <div className='w-full'>
      <div className="mb-10 mt-3 w-full">
        {
          user.langue === 'AR' ?
            <div className="flex gap-2 w-full justify-end items-end">
              
              <div className="flex w-full justify-end">
                <p className="text-xl font-bold tracking-tight text-slate-900">
                  {titleAr}
                </p>
              </div>
              <div className=" flex w-auto h-auto text-3xl font-bold tracking-tight text-slate-900 ">
                {icon}
              </div>
            </div>
            :
            <div className="flex gap-2 w-full">
              <div className=" flex w-auto h-auto text-3xl font-bold tracking-tight text-slate-900 ">
                {icon}
              </div>
              <div className="flex w-full justify-between">
                <p className="text-xl font-bold tracking-tight text-slate-900">
                  {title}
                </p>
                
              </div>
            </div>
        }
      </div>
    </div>
  )
}

export default Header
