import React, { useEffect, useState } from 'react'
import { useStateContext } from '../contexts/ContextProvider';

const Button = ({ icon, width, color, size, text, textAr, textColor, onClick }) => {
  const { setToken, user, } = useStateContext();
  const [libelleAr, setLibelleAr] = useState('');
  
  useEffect(() => {
    if (text === 'Enregistrer') {
      setLibelleAr('حفظ');
    }
    if (text === 'Fermer') {
      setLibelleAr('إغلاق');
    }
    if (text === 'Chercher') {
      setLibelleAr('بحث');
    }
    if (text === 'Exporter') {
      setLibelleAr('تحميل');
    }
    if (text === 'Journal') {
      setLibelleAr('السجل المخالصات');
    }
    if (text === 'Bilan') {
      setLibelleAr('الحالة المالية');
    }
    if (text === 'Se connecter') {
      setLibelleAr('تسجيل الدخول');
    }
    if (text === 'Ajouter') {
      setLibelleAr('إضافة');
    }

    if (text === 'Imprimer') {
      setLibelleAr('سحــب');
    }
    if (text === 'Cloturer') {
      setLibelleAr('موافقة');
    }
    if (text === 'Noter') {
      setLibelleAr('حفظ');
    }

  },[])
  return (
    // <button type='button' style={{ backgroundColor:bgColor,color,borderRadius }} 
    // className={`text-${size} p-3 hover:drop-shadow-xl`}>
    //   {text}
    // </button>"bg-blue-500 hover:shadow-xl text-white font-bold py-2 px-2  rounded inline-flex items-center"
    <button onClick={onClick} className={`${width} justify-center ${color} text-${size} hover:shadow-xl ${textColor} font-bold py-2 px-2 rounded inline-flex items-center`}>
      {icon}
      
        {
          user.langue === 'AR' ?
            <span className='ml-1'>{libelleAr}</span>
            :
            <span className='ml-1'>{text}</span>
        }
      
    </button>
  )
}

export default Button
