import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import { SiShopware } from 'react-icons/si';
import { MdOutlineCancel } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { links } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import { RiMoneyEuroCircleFill } from 'react-icons/ri';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { BsFillBuildingFill } from 'react-icons/bs';
import AdminRole from './roles/AdminRole';
import OperateurRole from './roles/OperateurRole';
import CaissierRole from './roles/CaissierRole';
import DrEtudeRole from './roles/DrEtudeRole';
import EnseignantRole from './roles/EnseignantRole';
const Sidebar = () => {

  const activeLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg text-white bg-blue-600 text-md m-2';
  const normalLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg  text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
  const { activeMenu, setActiveMenu, screenSize, user, currentColor } = useStateContext();
  const handleCloseSidebar = () => {
    if (screenSize <= 900) {
      setActiveMenu(false);

    }
  }
  return (
    <div translate='no' className='ml-3 h-full md:overflow-hidden overflow-auto
      md:hover:overflow-auto  pb-10'>
      {activeMenu && (<>
          {/* Menu header */}
          <div className='flex justify-between items-center'>
            <Link to='/' onClick={handleCloseSidebar} className='flex items-center gap-3 ml-3
          mt-4 text-xl font-bold tracking-tight text-slate-900 dark:text-white'>
              <SiShopware /> <span>School App</span>
            </Link>
            <TooltipComponent content='Menu' position='BottomCenter'>
              <button type='button'
                onClick={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)} className='text-xl rounded-full
            p-3 hover:bg-light-gray block md:hidden  mt-4 '>
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <div className="mt-10">
            {user.role && user.role.name === 'admin' ?
              <AdminRole />
              :
              user.role && user.role.name === 'caissier' ?
              <CaissierRole/>
              :
              user.role && user.role.name === 'dretude' ? <DrEtudeRole/> 
              :
              user.role && user.role.name === 'enseignant' ? <EnseignantRole/> 
              :<></>

            }
          </div>
        </>
        )
      }
    </div>
  )
}

export default Sidebar
