import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import Select from 'react-select';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import SelectMulti from '../../components/SelectMulti';
import { IoIosSave } from 'react-icons/io';
import { GiCancel } from 'react-icons/gi';
import { LuFileSpreadsheet } from 'react-icons/lu';
import PrintRecipiet from './PrintRecipiet';
import MySelect from '../../components/MySelect';

const AddFacture = ({ onClose, inscription, mensualite }) => {
    const [loading, setLoading] = useState(false);
    const [moisScolaires, setMoisScolaires] = useState([]);
    const [selected, setSelected] = useState([]);
    const { token, setToken } = useStateContext();
    const [errors, setErrors] = useState(null);
    const [showPrint, setShowPrint] = useState(false);
    const [selectedPaiement, setSelectedPaiement] = useState(null);
    const [selectedFacture, setSelectedFacture] = useState(null);
    const [type, setType] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [inputValueType, setValueType] = useState('');
    const [facture, setFacture] = useState({
        'id': '',
        'frais': 0,
        'uniforme': 0,
        'mois': selected,
        'restaurant': 0,
        'paiement': 0,
        'dispense': 0,
        'total': 0,
        'inscription': inscription,
        'typePaiement': '',

    })
    useEffect(() => {
        getMois();
        getTypes();
    }, []);
    useEffect(() => {
        var tot = parseFloat(selected.length * mensualite) + parseFloat(facture.frais) + parseFloat(facture.uniforme) + parseFloat(facture.restaurant) - parseFloat(facture.dispense);
        setFacture({ ...facture, mois: selected, total: tot });
    }, [selected]);
    useEffect(() => {
        let tot = parseFloat(selected.length * mensualite) + parseFloat(facture.frais) + parseFloat(facture.uniforme) + parseFloat(facture.restaurant) - parseFloat(facture.dispense);
        setFacture({ ...facture, total: tot });

    }, [facture.frais, facture.dispense, facture.uniforme, facture.restaurant]);
    const getMois = () => {
        axiosClient.get('/mois-scolaires')
            .then((data) => {
                setMoisScolaires(data.data.mois);
                setLoading(false);
            })
    }
    const navigate = useNavigate();

    const onSubmit = () => {

        setErrors(null);
        setLoading(true);

        axiosClient.post('/facture/save', facture)
            .then((response) => {
                setLoading(false);
                setErrors(null);
                setSelectedPaiement(response.data.paiement);
                setSelectedFacture(response.data.facture);
                setLoading(false);
                if (response.data.message === 'paiement') {
                    setShowPrint(true);
                } else {
                    onClose();
                }

            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }

    const getTypes = () => {
        axiosClient.get('/typePaiements')
            .then((data) => {
                setType(data.data.types);
            })
    }
    const handleInputChangeType = value => {
        setValueType(value);
    };

    // handle selection


    // handle selection
    const handleChangeType = value => {
        setSelectedType(value);
        setFacture({ ...facture, typePaiement: value.libelle });

    }


    const closePrint = () => {
        setShowPrint(false);
        setSelectedPaiement(null);
        onClose();
    }

    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto rounded-xl'>
                        <div className=''>
                            <Header icon={<LuFileSpreadsheet />} title="Créer une facture" titleAr={"إنشاء فاتورة"} />
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }

                        <div className='flex gap-2'>
                            <div className='flex-col justify-evenly '>
                                <div className='mr-2'>
                                    <Input label="Frais" labelAr="رسوم التسجيل" type="number" value={facture.frais} onChange={ev => setFacture({ ...facture, frais: ev.target.value })} required="required" />
                                </div>
                                <div className='mr-2'>
                                    <Input label="Uniforme" labelAr="الزي الموحد" type="number" value={facture.uniforme} onChange={ev => setFacture({ ...facture, uniforme: ev.target.value })} required="required" />
                                </div>
                                <div className='mr-2'>
                                    <Input label="Restaurant" labelAr="خدمة المطعم" type="number" value={facture.restaurant} onChange={ev => setFacture({ ...facture, restaurant: ev.target.value })} required="required" />
                                </div>
                                <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap w-full   mt-2'>
                                    <SelectMulti label="Mois" labelAr="الأشهر" selected={selected} setSelected={setSelected} options={moisScolaires} required="required" />
                                </div>

                            </div>
                            <div className='flex-col justify-evenly '>
                                <div className='mr-2'>
                                    <Input label="Dispense" labelAr="الإعفاء" type="number" value={facture.dispense} onChange={ev => setFacture({ ...facture, dispense: ev.target.value })} required="required" />
                                </div>
                                <div className='mr-2'>
                                    <Input label="Net à payer" labelAr="المبلغ المطالب به" type="number" value={facture.total} onChange={ev => setFacture({ ...facture, uniforme: ev.target.value })} required="required" />
                                </div>
                                <div className='mr-2'>
                                    <Input label="Montant Payé" labelAr="المبلغ المدفوع" type="number" value={facture.paiement} onChange={ev => setFacture({ ...facture, paiement: ev.target.value })} required="required" />
                                </div>
                                <div className="flex-col mr-2 mt-2 w-full">
                                    <MySelect label="M.paiement" labelAr="وسيلة الدفع" placeholder="M.Paiement" cacheOptions defaultOptions value={selectedType} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={type} onInputChange={handleInputChangeType} onChange={handleChangeType} />
                                </div>
                            </div>
                        </div>

                        <div className='flex justify-end items-end'>
                            <div className='flex m-4 gap-3 justify-between'>
                                {loading ? <Loading /> :
                                    <Button icon={<IoIosSave />} onClick={onSubmit} color="ml-1 bg-green-500" textColor="text-white" text="Enregistrer" />

                                }
                                <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {
                showPrint ? <PrintRecipiet paiement={selectedPaiement} onClose={closePrint} facture={selectedFacture} /> : <></>
            }
        </>

    )
}

export default AddFacture